/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import constants from './constants';
import * as storeApi from '../../shared/services/store/tracks';
import * as learnSkillApi from '../../shared/services/learn/skills';
import * as learnCheckpointApi from '../../shared/services/learn/checkpoints';
import * as learnOneToOneApi from '../../shared/services/learn/oneToOnes';
import * as learnWorkshopApi from '../../shared/services/learn/workshops';
import * as groupApi from '../../shared/services/instructor/groups';
import * as tracksApi from '../../shared/services/instructor/tracks';
import * as allocationApi from '../../shared/services/capacity/allocations';
import * as workShopsApi from '../../shared/services/instructor/workshops';
import * as checkpointsApi from '../../shared/services/instructor/checkpoints';
import * as oneToOnesApi from '../../shared/services/instructor/oneToOnes';
import * as contentApi from '../../shared/services/content/resources';
import { ITEM_KIND } from './types';

export function* setNavigationIndex(action: {
  type: string;
  body: number;
}) {
  try {
    yield put({
      type: constants.setNavigationIndex.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setNavigationIndex.failure,
      e,
    });
  }
}

export function* setNavigationIndexWatcher() {
  yield takeEvery(constants.setNavigationIndex.request, setNavigationIndex);
}

export function* fetchSkeleton(action: {
  type: string;
  values: {
    query: [];
    trackId: string;
  };
}) {
  try {
    const result: ReturnType<typeof storeApi.fetchSkeleton> = yield call(storeApi.fetchSkeleton, action.values);
    yield put({
      type: constants.fetchSkeleton.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchSkeleton.failure,
      e,
    });
  }
}

export function* fetchSkeletonWatcher() {
  yield takeEvery(constants.fetchSkeleton.request, fetchSkeleton);
}

export function* getActiveStudents(action: {
  type: string;
  values: {
    query: [];
    groupId: string;
  };
}) {
  try {
    const result: ReturnType<typeof groupApi.getActiveStudents> = yield call(groupApi.getActiveStudents, action.values);
    yield put({
      type: constants.getActiveStudents.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getActiveStudents.failure,
      e,
    });
  }
}

export function* getActiveStudentsWatcher() {
  yield takeEvery(constants.getActiveStudents.request, getActiveStudents);
}

const fetchApi = {
  [ITEM_KIND.SKILL.index]: learnSkillApi.getSkill,
  [ITEM_KIND.SUPER_SKILL.index]: learnSkillApi.getSkill,
  [ITEM_KIND.CHECKPOINT.index]: learnCheckpointApi.fetchCheckpointPreviewMode,
  [ITEM_KIND.ONE_TO_ONE.index]: learnOneToOneApi.fetchOneToOnePreviewMode,
  [ITEM_KIND.WORKSHOP.index]: learnWorkshopApi.fetchWorkshopPreviewMode,
};

export function* getItem(action: any) {
  const {
    values: { kind, nodeId, trackId },
  } = action;
  try {
    const result: Promise<any> = yield call(fetchApi[kind], trackId, nodeId);
    yield put({
      type: constants.getItem.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.getItem.failure,
      e,
    });
  }
}

export function* getItemWatcher() {
  yield takeEvery(constants.getItem.request, getItem);
}

export function* patchBookMark(action: any) {
  try {
    const result: ReturnType<typeof tracksApi.patchBookMark> = yield call(tracksApi.patchBookMark, action.values);
    yield put({
      type: constants.patchBookMark.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.patchBookMark.failure,
      e,
    });
  }
}

export function* patchBookMarkWatcher() {
  yield takeEvery(constants.patchBookMark.request, patchBookMark);
}

export function* getBookMarkedNodes(action: {
  type: string;
  values: {
    trackId: string;
    query: [];
  };

}) {
  try {
    const result: ReturnType<typeof tracksApi.getBookMarkedNodes> = yield call(tracksApi.getBookMarkedNodes, action.values);
    yield put({
      type: constants.getBookMarkedNodes.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.getBookMarkedNodes.failure,
      e,
    });
  }
}

export function* getBookMarkedNodesWatcher() {
  yield takeEvery(constants.getBookMarkedNodes.request, getBookMarkedNodes);
}

export function* setCurriculumOpen(action: {
  type: string;
  body: boolean;
}) {
  try {
    yield put({
      type: constants.setCurriculumOpen.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setCurriculumOpen.failure,
      e,
    });
  }
}

export function* setCurriculumOpenWatcher() {
  yield takeEvery(constants.setCurriculumOpen.request, setCurriculumOpen);
}

export function* openFeedbackModal(action: {
  type: string;
  body: boolean;
}) {
  try {
    yield put({
      type: constants.openFeedbackModal.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.openFeedbackModal.failure,
      e,
    });
  }
}

export function* openFeedbackModalWatcher() {
  yield takeEvery(constants.openFeedbackModal.request, openFeedbackModal);
}

export function* postSkillFeedback(action: {
  type: string;
  body: {};
}) {
  try {
    const result: ReturnType<typeof allocationApi.postSkillFeedback> = yield call(allocationApi.postSkillFeedback, action.body);
    yield put({
      type: constants.postSkillFeedback.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.postSkillFeedback.failure,
      e,
    });
  }
}

export function* postSkillFeedbackWatcher() {
  yield takeEvery(constants.postSkillFeedback.request, postSkillFeedback);
}

export function* getResources(action: {
  type: string;
  values: {
    trackId: string;
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof tracksApi.getResources> = yield call(tracksApi.getResources, action.values);
    yield put({
      type: constants.getResources.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.getResources.failure,
      e,
    });
  }
}

export function* getResourcesWatcher() {
  yield takeEvery(constants.getResources.request, getResources);
}

export function* patchResources(action: {
  type: string;
  values: {
    trackId: string;
    query: [];
    body: {};
  }
}) {
  try {
    const result: ReturnType<typeof tracksApi.patchResources> = yield call(tracksApi.patchResources, action.values);
    yield put({
      type: constants.patchResources.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.patchResources.failure,
      e,
    });
  }
}
export function* patchResourcesFromTrack(
  action: {
    type: string;
    values: {
      trackId: string;
      query: [];
      body: {};
    };
  },
) {
  try {
    const result: ReturnType<typeof tracksApi.patchResources> = yield call(tracksApi.patchResources, action.values);
    yield put({
      type: constants.patchResourcesFromTrack.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.patchResourcesFromTrack.failure,
      e,
    });
  }
}

export function* patchResourcesWatcher() {
  yield takeEvery(constants.patchResources.request, patchResources);
}
export function* patchResourcesFromTrackWatcher() {
  yield takeEvery(
    constants.patchResourcesFromTrack.request,
    patchResourcesFromTrack,
  );
}

export function* setEditModalOpen(action: {
  type: string;
  body: boolean;
}) {
  try {
    yield put({
      type: constants.setEditModalOpen.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setEditModalOpen.failure,
      e,
    });
  }
}

export function* setEditModalOpenWatcher() {
  yield takeEvery(constants.setEditModalOpen.request, setEditModalOpen);
}

export function* setAddModalOpen(action: {
  type: string;
  body: boolean;
}) {
  try {
    yield put({
      type: constants.setAddModalOpen.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setAddModalOpen.failure,
      e,
    });
  }
}

export function* setAddModalOpenWatcher() {
  yield takeEvery(constants.setAddModalOpen.request, setAddModalOpen);
}

export function* getTrackStats(action: {
  type: string;
  values: {
    trackId: string;
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof storeApi.getTrackStats> = yield call(storeApi.getTrackStats, action.values);
    yield put({
      type: constants.getTrackStats.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.getTrackStats.failure,
      e,
    });
  }
}

export function* getTrackStatsWatcher() {
  yield takeEvery(constants.getTrackStats.request, getTrackStats);
}

export function* setUnsavedModalOpen(action: {
  type: string;
  body: boolean;
}) {
  try {
    yield put({
      type: constants.setUnsavedModalOpen.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setUnsavedModalOpen.failure,
      e,
    });
  }
}

export function* setUnsavedModalOpenWatcher() {
  yield takeEvery(constants.setUnsavedModalOpen.request, setUnsavedModalOpen);
}

export function* getOrderedWorkshops(action: {
  type: string;
  values: {
    trackId: string;
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof workShopsApi.getOrderedWorkshops> = yield call(workShopsApi.getOrderedWorkshops, action.values);
    yield put({
      type: constants.getOrderedWorkshops.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.getOrderedWorkshops.failure,
      e,
    });
  }
}

export function* getOrderedWorkshopsWatcher() {
  yield takeEvery(constants.getOrderedWorkshops.request, getOrderedWorkshops);
}

export function* getOrderedCheckpoints(action: {
  type: string;
  values: {
    trackId: string;
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof checkpointsApi.getOrderedCheckpoints> = yield call(checkpointsApi.getOrderedCheckpoints, action.values);
    yield put({
      type: constants.getOrderedCheckpoints.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.getOrderedCheckpoints.failure,
      e,
    });
  }
}

export function* getOrderedCheckpointsWatcher() {
  yield takeEvery(constants.getOrderedCheckpoints.request, getOrderedCheckpoints);
}

export function* getOrderedOneToOnes(action: {
  type: string;
  values: {
    trackId: string;
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof oneToOnesApi.getOrderedOneToOnes> = yield call(oneToOnesApi.getOrderedOneToOnes, action.values);
    yield put({
      type: constants.getOrderedOneToOnes.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.getOrderedOneToOnes.failure,
      e,
    });
  }
}

export function* getOrderedOneToOnesWatcher() {
  yield takeEvery(constants.getOrderedOneToOnes.request, getOrderedOneToOnes);
}

export function* resetNotification() {
  try {
    yield put({
      type: constants.resetNotification.success,
    });
  } catch (e) {
    yield put({
      type: constants.resetNotification.failure,
      e,
    });
  }
}

export function* resetNotificationWatcher() {
  yield takeEvery(constants.resetNotification.request, resetNotification);
}

export function* setShowCurriculum(action: {
  type: string;
  body: boolean;
}) {
  try {
    yield put({
      type: constants.setShowCurriculum.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setShowCurriculum.failure,
      e,
    });
  }
}

export function* setShowCurriculumWatcher() {
  yield takeEvery(constants.setShowCurriculum.request, setShowCurriculum);
}

function* getResourcesBySkillId(action: {
  type: string;
  id: string;
}) {
  try {
    const result: ReturnType<typeof contentApi.default.getResourcesBySkillId> = yield call(contentApi.default.getResourcesBySkillId, action.id);
    yield put({
      type: constants.getResourcesBySkillId.success,
      data: result,
      request: action.id,
    });
  } catch (e) {
    yield put({
      type: constants.getResourcesBySkillId.failure,
      e,
    });
  }
}

export function* getResourcesBySkillIdWatcher() {
  yield takeEvery(constants.getResourcesBySkillId.request, getResourcesBySkillId);
}

export default function* trackPreviewSaga() {
  yield all([
    setNavigationIndexWatcher(),
    fetchSkeletonWatcher(),
    getActiveStudentsWatcher(),
    getItemWatcher(),
    patchBookMarkWatcher(),
    getBookMarkedNodesWatcher(),
    setCurriculumOpenWatcher(),
    openFeedbackModalWatcher(),
    postSkillFeedbackWatcher(),
    getResourcesWatcher(),
    patchResourcesWatcher(),
    setEditModalOpenWatcher(),
    setAddModalOpenWatcher(),
    getTrackStatsWatcher(),
    setUnsavedModalOpenWatcher(),
    getOrderedWorkshopsWatcher(),
    getOrderedCheckpointsWatcher(),
    getOrderedOneToOnesWatcher(),
    resetNotificationWatcher(),
    patchResourcesFromTrackWatcher(),
    setShowCurriculumWatcher(),
    getResourcesBySkillIdWatcher(),
  ]);
}
